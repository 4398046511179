<template>
  <v-container>
    <div class="header">
      <div class="combo_section">
        <v-select dense outlined  rounded class="combobox ma-1"
          id="farm" 
          ref="farm" 
          label="농장✽" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @focus="comboEsgFarms()"
          >
          <template v-slot:item="slotProps" >
            {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.farmNo}}</span>
          </template>
        </v-select>

        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense outlined class="calendar shrink rounded mt-1 ml-1 mb-1"
              id="fr_date" 
              ref="fr_date" 
              v-model="fr_date"
              label="부터"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title locale="ko-KR"
            v-model="fr_date"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense outlined  class="calendar shrink rounded mt-1 ml-2  mb-1 mr-1"
              id="to_date" 
              ref="to_date" 
              v-model="to_date"
              label="까지"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title locale="ko-KR"
            v-model="to_date"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </div>

      <div class="combo_section mb-3" style="margin-top:-30px;">
        <div class="radio_content mt-3 mr-2">
          <div style="margin-top:-10px">
            <v-radio-group dense outlined row 
              id="val" 
              ref="val" 
              v-model="val"
            >
              <v-radio label="연간 배출량" value="year" color="#3a4f3f"/>
              <v-radio label="KG 당 연간 배출량" value="kg" color="#3a4f3f"/> 
            </v-radio-group>
          </div>
        </div>

        <div class="radio_content backsky mt-3">
          <div style="margin-top:-10px">
            <v-radio-group dense outlined row 
              id="gas" 
              ref="gas" 
              v-model="gas"
            >
              <v-radio label="전체" value="0" color="#9EB0A2" 
                @click="monitoringEsgValueOfFarm()" />
              <v-radio label="메탄" value="1" color="#6B7B6E"
                @click="monitoringEsgValueOfFarm()" /> 
              <v-radio label="이산화질소" value="2" color="#304E5C"
              @click="monitoringEsgValueOfFarm()" /> 
            </v-radio-group>
          </div>
        </div>

        <v-btn class="new-btn mt-4 ml-1" @click="monitoringEsgValueOfFarm()" :loading="loading">조회</v-btn>
      </div>
    </div>

    <div class="chart_Layout" v-if="this.val == 'year'">
      <div class="chart_title">
        {{ selectedLabel }} 연간 배출량 
      </div>

  
      <Bar
        :chart-options="options"
        :chart-data="emissionData"
        style="position: relative; height:250px; width:80%; margin: 0 auto; "
      />
    </div>

    <div class="chart_Layout" v-else>
      <div class="chart_title">
        {{ selectedLabel }} Kg당 연간 배출량
      </div>
      
      <Bar
        :chart-options="options"
        :chart-data="emissionPerKgData"
        style="position: relative; height:250px; width:80%; margin: 0 auto; "
      />
    </div>

    <div class="table_content">
      <div class="table_title" style="text-align:left;">
        <div>배출량 상세보기</div>
        <div style="font-size:13px; font-weight:normal;">전체적인 현재 배출량 및 목표 배출량을 비교하여 나타내 줍니다.</div>
      </div>

      <div class="table_Layout" style="margin-right:60px;">
        <table class="table" v-if="this.val == 'year'">
          <tr>
            <th>현재 총 배출량</th>
            <td>{{ now_emission.toString().split('.')[0] }}</td>
          </tr>
          <tr>
            <th>목표 총 배출량</th>
            <td>{{ goal_emission.toString().split('.')[0]}}</td>
          </tr>
          <tr>
            <th>필요 감축 배출량</th>
            <td>{{ need_emission.toString().split('.')[0] }} </td>
          </tr>
        </table>

        <table class="table" v-else>
          <tr>
            <th>현재 총 배출량</th>
            <td>{{ now_emissionPerKg.toString().split('.')[0] }}</td>
          </tr>
          <tr>
            <th>목표 총 배출량</th>
            <td>{{ goal_emissionPerKg.toString().split('.')[0]}}</td>
          </tr>
          <tr>
            <th>필요 감축 배출량</th>
            <td>{{ need_emissionPerKg.toString().split('.')[0] }} </td>
          </tr>
        </table>
      </div>
    </div>

  </v-container>
</template>
<script>
import Apis from '@/api/apis';
import dateUtil from "@/utils/custom/dateProperty.js";
import VueCookies from "vue-cookies";

import { Bar }  from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'MonitorEsg',

  components: { Bar },

  async created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    await this.comboEsgFarms();

    let cookie_info = VueCookies.get("esg_monitor");

    if (cookie_info) {
      this.farm = cookie_info.farm || {};
      this.tier = cookie_info.tier
    } else {
      this.farm = this.farmItems[0];
      this.tier = "2";
    }
    
    let cdate = new Date();
    this.to_date= dateUtil.format(cdate, "yyyy-MM");
    let frdate = new Date(cdate.setDate(cdate.getDate() - 365));
    this.fr_date= dateUtil.format(frdate, "yyyy-MM");

  },
  watch:{
    tier() {
      this.monitoringEsgValueOfFarm();
    },
    farm(){
      this.monitoringEsgValueOfFarm();
    }
  },
  data(){
    return {
      loading: false,
      farm : {},
      farmItems : [],

      fr_date: "",
      to_date: "",

      menu1: false,
      menu2: false,

      month: "12",
      
      tier:'2',
      val : 'year',
      gas : '0',
      gaslabel : '전체',

      reference:{},

      goal_emissionPerKg:'',
      now_emissionPerKg:'',
      need_emissionPerKg:'',

      goal_emission:'',
      now_emission:'',
      need_emission:'',

      // goal_emission:'',

      options: {
        // responsive: false,
        maintainAspectRatio: false,
        maxBarThickness: 55, // 막대 굵기
        interaction: {
          mode: 'index',
        },
        plugins:{
          legend:{
            position:'bottom',
          }
        },
        scales: {
          x: {
            stacked: true,
            grid: { 
              display: false,
              drawTicks: true, 
              tickLength: 4,
              color: '#E2E2E230' 
            },
          },
          y: {
            stacked: true,
          }
        }
      },
      emissionData: { },
      emissionPerKgData: { },

    }
  },
  computed: {
    selectedLabel() {
      const labels = {
        '0': '전체',
        '1': '메탄',
        '2': '이산화질소'
      };
      return labels[this.gas];
    }
  },

  methods:{
    monitoringEsgValueOfFarm(){
      this.$store.commit("resMessage","");
      this.loading = true;

      // console.log(this.selectedLabel)
      Apis.monitoringEsgValueOfFarm({
        farmNo: this.farm.farmNo,
        stand_date: dateUtil.format(new Date(), "yyyy-MM-01"), 
        month:12,
        tier: 2,
        gas : Number(this.gas)
        },(res) => {  
          if (res.result) {
            // console.log(this.gaslabel, this.gas)
            this.loading = false;
            
            this.reference = res.data.reference;
            this.goal_emission = res.data.reference.curr_emission - res.data.reference.target_emission;

            this.now_emission = res.data.emission.etc[1] +res.data.emission.intestinal[1] + res.data.emission.excreta[1] + res.data.emission.energy[1];

            this.goal_emission = res.data.emission.etc[2] +res.data.emission.intestinal[2] + res.data.emission.excreta[2] + res.data.emission.energy[2];

            this.need_emission = this.now_emission - this.goal_emission;

            this.now_emissionPerKg = res.data.emissionPerKg.etc[1] +res.data.emissionPerKg.intestinal[1] + res.data.emissionPerKg.excreta[1] + res.data.emissionPerKg.energy[1];

            this.goal_emissionPerKg = res.data.emissionPerKg.etc[2] +res.data.emissionPerKg.intestinal[2] + res.data.emissionPerKg.excreta[2] + res.data.emissionPerKg.energy[2];

            this.need_emissionPerKg = this.now_emissionPerKg - this.goal_emissionPerKg;

            this.emissionData = {
              labels: ['한국 농장 평균', '내 농장 평균', '저탄소 농장 평균'], 
              datasets: [
                {
                  label: '장내발효', 
                  data: res.data.emission.intestinal, 
                  backgroundColor: 'rgba(21, 52, 72, 0.6)',
                  borderWidth: 1 
                },
                {
                  label: '분뇨처리', 
                  data: res.data.emission.excreta, 
                  backgroundColor: 'rgba(60, 91, 111, 0.6)', 
                  borderWidth: 1 
                },
                {
                  label: '에너지', 
                  data: res.data.emission.energy, 
                  backgroundColor: 'rgba(143, 137, 121, 0.6)',
                  borderWidth: 1 
                },
                {
                  label: '그 외', 
                  data: res.data.emission.etc, 
                  backgroundColor: 'rgba(233, 208, 184, 0.6)', 
                  borderWidth: 1 
                }
              ]
            };
            this.emissionPerKgData = {
              labels: ['한국 농장 평균', '내 농장 평균', '저탄소 농장 평균'], 
              datasets: [
                {
                  label: '장내발효',
                  data: res.data.emissionPerKg.intestinal, 
                  backgroundColor: 'rgba(7, 65, 115, 0.6)', 
                  borderWidth: 1
                },
                {
                  label: '분뇨처리',
                  data: res.data.emissionPerKg.excreta, 
                  backgroundColor: 'rgba(22, 121, 171, 0.6)',
                  borderWidth: 1 
                },
                {
                  label: '에너지', 
                  data: res.data.emissionPerKg.energy, 
                  backgroundColor: 'rgba(93, 235, 215, 0.6)',
                  borderWidth: 1
                },
                {
                  label: '그 외', 
                  data: res.data.emissionPerKg.etc, 
                  backgroundColor: 'rgba(197, 255, 149, 0.6)', 
                  borderWidth: 1 
                }
              ]
            };

            this.$store.commit("resMessage",res.message);
            let cookie_info = { farm: this.farm, tier: this.tier};
            VueCookies.set("esg_monitor" ,cookie_info,"30d");
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("monitoringEsgValueOfFarm API 호출 오류",err)
        }
      ) 
    },
    async comboEsgFarms() {
      await Apis.comboEsgFarms({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              code: res.data[i].farm.code,
              name: res.data[i].farm.name,
              farmNo: res.data[i].farm.farmNo
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
  }
}
</script>
<style lang="scss" scoped>
.radio_content{
  border: 1px solid #e4e4e4;
  border-radius: 30px;
  height:45px;
}
.backsky{
  background-color:#e8f3f5c5;
}
.header{
  border-radius: 10px;
}
.combo_section{
  display:flex;
  justify-content: center;
}
.v-menu__content{
  max-width:200px !important;
}
.combobox{
  max-width:200px;
}
.calendar{
  max-width:120px;
  border-radius: 50px !important;
}
.items{
  font-size:13px;
}
.chart_Layout, .table_content{
  margin-top:10px !important;
  padding:10px;
  border:1px solid #e4e4e4;
  border-radius: 30px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}
.chart_title{
  font-size:18px;
  padding:10px;
  font-weight:600;
  letter-spacing: -1px;
  margin: 5px 0 10px 10px;
}
.table_title{
  font-size:18px;
  font-weight:600;
  margin:5px 15px;
}

.table_Layout{
  display:flex;
  letter-spacing: -1px;
  justify-content: center;
  padding:10px !important;
  background-color:white;
}
.table th{
  background:#47604d;
  color:white;
  padding:10px;
  text-align:left;
  width:180px;
  border-radius: 15px;
}
.table td{
  border:1px solid #3a4f3f;
  color:#3a4f3f;
  padding:10px;
  width:120px;
  font-size:18px;
  text-align: right;
  border-radius: 15px;
}
.new-btn{
  border-radius: 30px;
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
</style>